import { Button } from "primereact";
import styles from "./index.module.scss";
import BPointsLeaderboard from "Components/PointsFarm/BPointsLeaderboard";
import CacheBullTokenModal from "Components/PointsFarm/Modals/CacheBullToken";
import { useEffect, useState } from "react";
import CacheBullTokenSuccessModal from "Components/PointsFarm/Modals/CacheBullToken/Success";
import DeCacheBullTokenSuccessModal from "Components/PointsFarm/Modals/DeCacheBullToken/Success";
import DeCacheBullToken from "Components/PointsFarm/Modals/DeCacheBullToken";
import DeCacheBullTokenQueue from "Components/PointsFarm/Modals/DeCacheBullToken/Queue";
import HowItWorksModal from "Components/PointsFarm/Modals/HowItWorks";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios/axios";
import allActions from "redux/actions";
import moment from "moment";
import Config from "Config";
import DeCacheBullTokenWithdrawModal from "Components/PointsFarm/Modals/DeCacheBullToken/Withdraw";
import { useWeb3ModalAccount, useWeb3Modal } from "@web3modal/ethers5/react";
import { useNavigate } from "react-router-dom";
let x;
let EPOCSInterval = null

export default function PointsFarm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const [activeLeaderboardTab, setActiveLeaderboardTab] = useState(0);

    const { address } = useWeb3ModalAccount();
    const { open } = useWeb3Modal();

    const { userDetails } = useSelector(state => state.auth);
    const [showHowItWorksModal, setShowHowItWorksModal] = useState(false);

    const [showCacheBullTokenModal, setShowCacheBullTokenModal] = useState(false);
    const [showCacheBullTokenModalSuccessmodal, setShowCacheBullTokenModalSuccessmodal] = useState(false);

    const [showDeCacheBullTokenModal, setShowDeCacheBullTokenModal] = useState(false);
    const [showDeCacheBullTokenSuccessModal, setShowDeCacheBullTokenSuccessModal] = useState(false);
    const [showDeCacheBullTokenQueueModal, setShowDeCacheBullTokenQueueModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);

    const [stakingData, setStakingdata] = useState([]);
    const [usersStakingData, setUsersStakingdata] = useState([]);

    const [cachedAmount, setCachedAmount] = useState(0);
    const [deCachedAmount, setDeCachedAmount] = useState(0);

    const [amountInQueue, setAmountInQueue] = useState(0);
    const [unlockedAmount, setUnlockedAmount] = useState({ amount: 0, indexes: [] });

    const [sessionEndTime, setSessionEndTime] = useState(0);


    useEffect(() => {
        if (userDetails?.username) {
            getStakingData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails]);

    const getUnlockCount = async () => {
        try {
            let unlockCount = await dispatch(allActions.authActions.unlockCount())
            if (Number(unlockCount) > 0) {
                let unlocks = await dispatch(allActions.authActions.getUnlocks(0, unlockCount));
                let amount = 0;
                let indexes = [];
                let queueAmount = 0;
                unlocks.forEach((item, index) => {
                    if (moment().unix() > (Number(item.unlockTime) + Config.decacheUnlockInterval)) {
                        if (Number(item.amount) > 0) {
                            amount += Number(item.amount) / Math.pow(10, 18);
                            indexes.push(index);
                        }
                    } else {
                        queueAmount += (Number(item.amount) / Math.pow(10, 18));
                    }
                })
                setUnlockedAmount({ amount, indexes });
                setAmountInQueue(queueAmount)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setInitialLoading(false);
        }
    };

    const getStakingData = async () => {
        clearInterval(x);
        try {
            let { response: { data, rank } } = await axios.get("/staking/me");
            if (data?.currentStaked !== undefined) {

                data["totalBeyondPoints"] = 0;
                let timedifference = moment().unix() - data.lastPointsUpdatedTimeStamp;
                data["timedifference"] = timedifference;

                let beyondPointsRate = (data.currentStaked * 0.009) / 86400;
                data["beyondPointsRate"] = beyondPointsRate;
                data["beyondPointsRatePerHour"] = beyondPointsRate * 3600;

                let initialBeyondPoints = Number(data.points) + Number(data.bonus) + Number(data.v2Points) + (timedifference * beyondPointsRate);
                data["points"] = initialBeyondPoints

                setStakingdata({ data, rank: rank });
                multiplyPoints(data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            getUnlockCount();
        }
    };

    const multiplyPoints = (initialValues) => {
        let SD = initialValues;

        let incrementdBeyondPoint = SD.points + SD.beyondPointsRate;
        let totalBeyondPoints = incrementdBeyondPoint * (1 + Number(SD.multiplier));
        let data = {
            "ethAddress": SD.ethAddress,
            "totalStaked": SD.totalStaked,
            "currentStaked": SD.currentStaked,
            "multiplier": SD.multiplier,
            "points": incrementdBeyondPoint,
            "lastMutliplierUpdatedTimeStamp": SD.lastMutliplierUpdatedTimeStamp,
            "lastPointsUpdatedTimeStamp": SD.lastPointsUpdatedTimeStamp,
            "timedifference": SD.timedifference,
            "totalBeyondPoints": totalBeyondPoints,
            "beyondPointsRate": SD.beyondPointsRate,
            "beyondPointsRatePerHour": SD.beyondPointsRatePerHour
        };

        setStakingdata({ data });

        x = setTimeout(() => {
            multiplyPoints(data)
        }, 970)
    }

    useEffect(() => {
        if (userDetails?.username) {
            getAllStakingUserData("points");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails]);

    const getAllStakingUserData = async (sortBy) => {
        try {
            let { response: { data: { data } } } = await axios.get(`/staking/users?limit=1000&sortBy=${sortBy}`);
            let users = {
                loggedInUser: [],
                allUsers: []
            }
            data.forEach((user, index) => {
                index++;
                if (index === 1) {
                    user["node_rewards"] = 30;
                } else if (index === 2) {
                    user["node_rewards"] = 20;
                } else if (index === 3) {
                    user["node_rewards"] = 15;
                } else if (index >= 4 && index <= 10) {
                    user["node_rewards"] = 10;
                } else if (index >= 11 && index <= 20) {
                    user["node_rewards"] = 5;
                } else if (index >= 21 && index <= 50) {
                    user["node_rewards"] = 3;
                } else if (index >= 51 && index <= 90) {
                    user["node_rewards"] = 2;
                } else if (index >= 91 && index <= 235) {
                    user["node_rewards"] = 1;
                } else {
                    user["node_rewards"] = 0;
                };
                user["rank"] = index;
                user["points"] = (Number(user.points) + Number(user.bonus)) * (1 + Number(user.multiplier));
                user["v2Points"] = (Number(user.v2Points)) * (1 + Number(user.multiplier));
                // user["points"] = (Number(user.points) + Number(user.bonus)) * (1 + Number(user.multiplier));

                if (user.ethAddress === userDetails.ethAddress) {
                    users.loggedInUser.push(user);
                } else {
                    users.allUsers.push(user);
                }
            })
            setUsersStakingdata(users);
        } catch (error) {
            console.error(error);
        }
    }

    const splitWalletAddress = (walletAddress) => {
        if (walletAddress) {
            return (
                walletAddress.substring(0, 6) +
                "..." +
                walletAddress.substring(walletAddress.length - 5, walletAddress.length)
            );
        }
    };

    useEffect(() => {
        getTimeDifference();
        return () => clearInterval(EPOCSInterval);
    }, [])

    const getTimeDifference = () => {
        EPOCSInterval = setInterval(() => {
            var now = moment().format("DD/MM/YYYY HH:mm:ss");
            var ms = moment(1733493600 * 1000).diff(moment(now, "DD/MM/YYYY HH:mm:ss"));
            let { _data } = moment.duration(ms);
            setSessionEndTime(_data);
        }, 1000);
    };

    return (
        <div className={styles.rightPanel}>

            <div className={styles.banner}>
                <div className={styles.tags}>
                    <div className={styles.tag}>
                        EP0CH 1
                    </div>
                    <div className={styles.tag}>
                        ENDS IN {sessionEndTime.days}D {sessionEndTime.hours}H {sessionEndTime.minutes}M
                    </div>
                </div>

                <div className={styles.infoArea}>
                    <div>
                        <h1>
                            Beyond CosmicCache
                        </h1>
                        <p>
                            Cache $BULL to earn Beyond Points, you  earn points based on how much you stake.
                        </p>
                    </div>
                    <div className={styles.tips} onClick={() => setShowHowItWorksModal(true)}>
                        <img src="/assets/icons/pointfarm/info.png" alt="icon" />
                        How it works
                    </div>
                </div>
            </div>
            {address
                ? (
                    <div className={styles.walletArea}>
                        <div className={styles.title}>
                            {address.toLowerCase() === userDetails?.ethAddress
                                ? (
                                    <div className={styles.greenDot}></div>
                                )
                                : (
                                    <div className={styles.redDot}></div>
                                )}

                            Wallet Connected
                        </div>
                        <div className={styles.address}>
                            <img src="/assets/icons/pointfarm/wallet_icon.png" alt="wallet_icon" />
                            {splitWalletAddress(address)}
                        </div>
                    </div>
                ) : (
                    <div className={styles.walletArea}>
                        <div className={styles.title}>
                            <div className={styles.redDot}></div>
                            No Wallet Connected
                        </div>
                        <div className={`${styles.address} ${styles.connect} cursor-pointer`} onClick={() => open()}>
                            <img src="/assets/icons/pointfarm/wallet_icon.png" alt="wallet_icon" />
                            Connect Wallet
                        </div>
                    </div>
                )}


            <div className={styles.pointsBox}>

                <div className={`${styles.box} ${styles.pointsXBox}`}>
                    <div className={styles.tag}>
                        Points x Multiplier
                    </div>
                    <div className={styles.points}>
                        {initialLoading
                            ? (
                                <i className="pi pi-spinner pi-spin text-4xl" />
                            ) : (
                                <>{stakingData.data?.totalBeyondPoints?.toLocaleString() || 0}</>
                            )
                        }
                    </div>
                    <div className={styles.title}>
                        Total Beyond Points
                        <Button
                            className="p-button-text p-0 w-1 tooltip-button"
                            icon="pi pi-info-circle"
                            tooltip="Your total Beyond Points determine the amount of $B Tokens you'll receive at the end of the final epoch."
                            tooltipOptions={{ position: 'top', className: "tooltip-help", appendTo: "self" }}
                        />
                    </div>
                    {userDetails?.ethAddress
                        ? (<Button label="Cache $BULL to Earn " className={styles.connectButton} onClick={() => setShowCacheBullTokenModal(true)} />)
                        : (<Button disabled={initialLoading} label="Link Your Wallet" className={styles.connectButton} onClick={() => navigate("/integration")} />)
                    }
                </div>

                <div className={styles.box}>
                    <div className={styles.subtitle}>
                        Beyond Points
                        <Button
                            className="p-button-text p-0 tooltip-button"
                            icon="pi pi-info-circle"
                            style={{ width: "20px" }}
                            tooltip="Earn Beyond Points based on the amount of $BULL you deposit, according to the current rate."
                            tooltipOptions={{ position: 'top', className: "tooltip-help", appendTo: "self" }}
                        />
                    </div>
                    <div className={styles.points}>
                        {initialLoading
                            ? (
                                <i className="pi pi-spinner pi-spin text-4xl" />
                            ) : (
                                <>
                                    {(stakingData.data?.points?.toLocaleString()) || 0}
                                </>
                            )
                        }
                    </div>
                    <div className={styles.links}>Earning <span>{stakingData.data?.beyondPointsRatePerHour?.toLocaleString() || 0} BP/Hour</span></div>
                </div>

                <div className={styles.box}>
                    <div className={styles.subtitle}>
                        Streak Multiplier
                        <Button
                            className="p-button-text p-0 tooltip-button"
                            style={{ width: "20px" }}
                            icon="pi pi-info-circle"
                            tooltip="Your Multiplier starts at 1X and increases weekly. Deposit earlier to maximize your earnings."
                            tooltipOptions={{ position: 'top', className: "tooltip-help", appendTo: "self" }}
                        />
                    </div>
                    <div className={styles.points}>
                        {initialLoading
                            ? (
                                <i className="pi pi-spinner pi-spin text-4xl" />
                            ) : (
                                <>
                                    {stakingData.data?.multiplier
                                        ? (
                                            <>
                                                {(1 + Number(stakingData.data?.multiplier)).toLocaleString() || 0}X
                                            </>
                                        )
                                        : (
                                            <>
                                                0X
                                            </>
                                        )}
                                </>
                            )
                        }
                    </div>
                    <div className={styles.links}>
                        Increases <span>0.05x</span> Per Week
                        {/* Cache Tokens To <span>Activate</span> */}
                    </div>
                </div>

            </div>

            <div className={styles.cachedAndWithdrawBox}>
                <div className={styles.box}>
                    <div className={styles.left}>
                        <div className={styles.points}>
                            {initialLoading
                                ? (
                                    <i className="pi pi-spinner pi-spin text-2xl" />
                                ) : (
                                    <>
                                        {stakingData.data?.currentStaked?.toLocaleString() || 0}
                                    </>
                                )
                            }
                        </div>
                        <div className={`${styles.title} flex align-items-center gap-1`}>
                            $BULL Staked
                            <Button
                                className="p-button-text p-0 tooltip-button"
                                style={{ width: "20px" }}
                                icon="pi pi-info-circle"
                                tooltip="Deposit (Cache) your $BULL to begin earning Beyond Points."
                                tooltipOptions={{ position: 'top', className: "tooltip-help", appendTo: "self" }}
                            />
                        </div>
                        <p>
                            Earning <span>Points</span> on this Amount
                        </p>
                    </div>
                    <button disabled={stakingData.data?.totalStaked <= 0 || initialLoading} className={styles.decachedButton} onClick={() => setShowDeCacheBullTokenModal(true)}>DeCache</button>
                </div>
                <div className={styles.box}>
                    <div className={styles.left}>
                        <div className={styles.points}>
                            {initialLoading
                                ? (
                                    <i className="pi pi-spinner pi-spin text-2xl" />
                                ) : (
                                    <>
                                        {unlockedAmount?.amount?.toLocaleString() || 0}
                                    </>
                                )
                            }
                        </div>
                        <div className={`${styles.title} flex align-items-center gap-1`}>
                            Unlocked Amount
                            <Button
                                className="p-button-text p-0 tooltip-button"
                                style={{ width: "20px" }}
                                icon="pi pi-info-circle"
                                tooltip="You can DeCache your $BULL, which takes a week. Once DeCaching is done, you can withdraw at any time."
                                tooltipOptions={{ position: 'top', className: "tooltip-help", appendTo: "self" }}
                            />
                        </div>
                        <p>
                            <span>
                                {initialLoading
                                    ? (
                                        <i className="pi pi-spinner pi-spin " />
                                    ) : (
                                        <>
                                            {amountInQueue?.toLocaleString() || 0}
                                        </>
                                    )
                                }

                            </span> BULL in <span className="text-white underline cursor-pointer" onClick={() => setShowDeCacheBullTokenQueueModal(true)}>Queue</span>
                        </p>
                    </div>
                    <button disabled={unlockedAmount?.amount <= 0 || initialLoading} className={styles.withdrawButton} onClick={() => setShowWithdrawModal(true)}>Withdraw</button>
                </div>
            </div>

            <div className={styles.leaderboardTabBar}>
                <div className={`${styles.tab} ${activeLeaderboardTab === 0 && styles.active}`} onClick={() => {
                    setActiveLeaderboardTab(0);
                    getAllStakingUserData("points");
                }}>Epoch 0</div>
                <div className={`${styles.tab} ${activeLeaderboardTab === 1 && styles.active}`} onClick={() => {
                    setActiveLeaderboardTab(1);
                    getAllStakingUserData("v2Points");
                }}>Epoch 1</div>
            </div>

            <BPointsLeaderboard usersStakingData={usersStakingData} initialLoading={initialLoading} activeLeaderboardTab={activeLeaderboardTab} />



            {showHowItWorksModal && (
                <HowItWorksModal showHowItWorksModal={showHowItWorksModal} setShowHowItWorksModal={(e) => setShowHowItWorksModal(e)} />
            )}

            {showCacheBullTokenModal && (
                <CacheBullTokenModal showCacheBullTokenModal={showCacheBullTokenModal} setShowCacheBullTokenModal={(e) => setShowCacheBullTokenModal(e)} setShowSuccessModel={(e) => setShowCacheBullTokenModalSuccessmodal(e)} setCachedAmount={(e) => setCachedAmount(e)} getStakingData={() => getStakingData()} />
            )}
            {showCacheBullTokenModalSuccessmodal && (
                <CacheBullTokenSuccessModal showCacheBullTokenModalSuccessmodal={showCacheBullTokenModalSuccessmodal} setShowCacheBullTokenModalSuccessmodal={(e) => setShowCacheBullTokenModalSuccessmodal(e)} cachedAmount={cachedAmount} />
            )}

            {showDeCacheBullTokenModal && (
                <DeCacheBullToken setDeCachedAmount={(e) => setDeCachedAmount(e)} showDeCacheBullTokenModal={showDeCacheBullTokenModal} setShowDeCacheBullTokenModal={(e) => setShowDeCacheBullTokenModal(e)} setShowDeCacheBullTokenSuccessModal={(e) => setShowDeCacheBullTokenSuccessModal(e)} />
            )}
            {showDeCacheBullTokenSuccessModal && (
                <DeCacheBullTokenSuccessModal
                    deCachedAmount={deCachedAmount}
                    showDeCacheBullTokenSuccessModal={showDeCacheBullTokenSuccessModal}
                    setShowDeCacheBullTokenSuccessModal={(e) => {
                        setShowDeCacheBullTokenSuccessModal(e);
                        setShowDeCacheBullTokenQueueModal(true);
                    }} />
            )}
            {showDeCacheBullTokenQueueModal && (
                <DeCacheBullTokenQueue showDeCacheBullTokenQueueModal={showDeCacheBullTokenQueueModal} setShowDeCacheBullTokenQueueModal={(e) => setShowDeCacheBullTokenQueueModal(e)} />
            )}

            {showWithdrawModal && (
                <DeCacheBullTokenWithdrawModal showWithdrawModal={showWithdrawModal} setShowWithdrawModal={(e) => setShowWithdrawModal(e)} withdrawableAmount={unlockedAmount} getUnlockCount={() => getUnlockCount()} />
            )}

        </div>
    )
}