import { useEffect } from "react";
import axios from "axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import allActions from "redux/actions";
import { smartWallet, inAppWallet } from "thirdweb/wallets";
import { getContract, defineChain } from "thirdweb";

export default function Protected() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(allActions.authActions.getUserDetails());
        dispatch(allActions.authActions.getBullTokenPrice());
        dispatch(allActions.authActions.getEthTokenPrice());
        connectToSmartAccount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { defaultChain, client, ERC20ContractAddress, USDCTokenAddress, clientId, factoryAddress, defaultChainId } = useSelector(state => state.auth);

    // eslint-disable-next-line no-unused-vars
    const connectToSmartAccount = async () => {
        try {

            let ERC20Contract = getContract({
                client,
                chain: defaultChain,
                address: ERC20ContractAddress,
                abi: [{
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        }
                    ],
                    "name": "transfer",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "",
                            "type": "bool"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                }]
            });

            let USDCContract = getContract({
                client,
                chain: defaultChain,
                address: USDCTokenAddress,
                abi: [{
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        }
                    ],
                    "name": "transfer",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "",
                            "type": "bool"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                }]
            });
            dispatch({ type: "LOAD_ERC20Contract", payload: ERC20Contract });
            dispatch({ type: "LOAD_USDCContract", payload: USDCContract });
            let { response: { randomKey } } = await axios.get("/auth/requestThirdWebKey");

            const personalWallet = inAppWallet({
                chain: defaultChain,
                clientId
            });

            let personalAccount = await personalWallet.connect({
                client,
                chain: defaultChain,
                strategy: "auth_endpoint",
                payload: JSON.stringify({
                    oauthKey: randomKey
                }),
                encryptionKey: "yourAccessSecretKey"
            });

            const Swallet = smartWallet({
                chain: defaultChain,
                factoryAddress,
                gasless: true,
                clientId
            });

            let smartAccount = await Swallet.connect({
                chain: defaultChainId,
                client,
                personalAccount
            });

            try {
                if (personalAccount) {
                    await axios.post(`/auth/saga/createOrGetAccount`, {
                        "address": personalAccount.address
                    });
                }



            } catch (error) {

            }


            try {
                let { response: { smartWalletDetails } } = await axios.get("/auth/smartwallet")
                if (smartWalletDetails === null) {
                    let { response: { message } } = await axios.get(`/auth/request/wallet/link?walletType=embedded&walletAddress=${personalAccount.address}`);
                    let signature = await personalAccount?.signMessage({
                        message
                    });
                    await axios.post("/auth/attach/smartwallet", {
                        walletAddress: personalAccount.address,
                        signature
                    });

                }
            } catch (error) {
                console.error(error);
            };

            dispatch({
                type: "LOAD_USER_ACCOUNT_DETAILS", payload: {
                    Swallet,
                    personalWallet,
                    smartAccount,
                    personalAccount
                }
            });
            dispatch(allActions.authActions.getUserWalletBalance());
            dispatch(allActions.authActions.getAllSigner());
            dispatch(allActions.authActions.getCredits());
            // connectToSagaChain();
        } catch (error) {
            console.error(error);
        };
    };

    // eslint-disable-next-line no-unused-vars
    const connectToSagaChain = async () => {
        let DefineChain = defineChain(2712713323097000);
        DefineChain["rpc"] = "https://bullieverse-2712713323097000-1.jsonrpc.sagarpc.io";

        try {

            const personalWallet = inAppWallet({
                chain: DefineChain,
                clientId
            });

            let { response: { randomKey } } = await axios.get("/auth/requestThirdWebKey");
            let personalAccount = await personalWallet.connect({
                client,
                chain: DefineChain,
                strategy: "auth_endpoint",
                payload: JSON.stringify({
                    oauthKey: randomKey
                }),
                encryptionKey: "yourAccessSecretKey"
            });

            const Swallet = smartWallet({
                chain: DefineChain,
                factoryAddress: "0x0163DD07B0a785BF0EC3D0c4B1c64A4183953E9a",
                gasless: true,
                clientId
            });


            await Swallet.connect({
                chain: DefineChain,
                client,
                personalAccount
            });
            // let { response: { message } } = await axios.get(`/auth/request/wallet/link?walletType=embedded&walletAddress=${personalAccount.address}`);
            // let signature = await personalAccount?.signMessage({
            //     message
            // });
            // await axios.post("/auth/attach/smartwallet", {
            //     walletAddress: personalAccount.address,
            //     signature
            // });
            // console.log(smartAccount)
        } catch (error) {
            console.error(error);
        };
    };

    return (
        <>
            <Outlet />
        </>
    )
}