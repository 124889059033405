import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Web3 from "web3";
import { BETS } from "Config/abis/BETS";
import { useSelector } from "react-redux";
import { Skeleton } from "primereact";

export default function Rewards() {
    const { userDetails } = useSelector(state => state.auth);
    const [activeTab, setActiveTab] = useState(1);
    const [NFTs, setNFTs] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [NFTCount, setNFTCount] = useState(0);

    useEffect(() => {
        const getNFTs = async () => {
            try {
                let web3 = new Web3("https://beyond-2727444691954000-1.jsonrpc.sagarpc.io");
                let methods = new web3.eth.Contract(BETS, "0xbEB1097c83BAE52712903bc029E7D60173BB0068").methods;

                let result = await methods.balanceOfBatch([userDetails?.ethAddress, userDetails?.ethAddress, userDetails?.ethAddress], [1, 2, 3]).call();
                // let result = [0, 0, 0];
                let NFTCount = result.filter(item => Number(item) > 0);
                setNFTCount(NFTCount.length);
                setNFTs(result);
                setIsFetching(false);
            } catch (error) {
                console.error(error);
                setIsFetching(false);
            };
        };
        if (userDetails?.username) {
            if (userDetails?.ethAddress) {
                getNFTs()
            } else {
                setIsFetching(false);
            }
        };
    }, [userDetails]);

    return (
        <div className={styles.rightPanel}>

            <h1>
                Rewards
                <img src="/assets/icons/rewards/rewards_icon.png" alt="icon" />
            </h1>
            <h2>
                You can view your raffle tickets and loot boxes here.
            </h2>

            <div className={styles.tabBar}>
                <div className={`${activeTab === 1 ? `${styles.tab} ${styles.active}` : `${styles.tab}`}`} onClick={() => setActiveTab(1)}>
                    NFT Tickets
                    <span className={styles.count}>{NFTCount}</span>
                </div>
                <div className={`${activeTab === 2 ? `${styles.tab} ${styles.active}` : `${styles.tab}`}`} onClick={() => setActiveTab(2)}>
                    LootBoxes
                    <span className={styles.count}>0</span>
                </div>
                <div className={`${activeTab === 3 ? `${styles.tab} ${styles.active}` : `${styles.tab}`}`} onClick={() => setActiveTab(3)}>
                    Raffles
                    <span className={styles.count}>0</span>
                </div>

            </div>


            {activeTab === 1
                ? (
                    <div className={isFetching ? styles.NFTBox : NFTCount === 0 ? undefined : styles.NFTBox}>
                        {isFetching
                            ? (
                                <>
                                    <div className={styles.nft}>
                                        <Skeleton className={styles.NFTskeleton} />
                                    </div>
                                </>
                            )
                            : (
                                <>
                                    {NFTCount > 0
                                        ? (
                                            <>
                                                {NFTs.map((nft, index) => (
                                                    <>
                                                        {Number(nft) > 0 && (
                                                            <div className={styles.nft}>
                                                                <img src={`/assets/NFTS/BETS/${index + 1}.png`} alt="nft" />
                                                            </div>
                                                        )}
                                                    </>
                                                ))}
                                            </>
                                        )
                                        : (
                                            <div className={styles.box}>
                                                <img src="/assets/icons/rewards/nodata.png" alt="nodata" />
                                                <div className={styles.nodata}>
                                                    No Items to Display
                                                </div>
                                            </div>
                                        )
                                    }

                                </>
                            )}

                    </div>
                )
                : (
                    <div className={styles.box}>
                        <img src="/assets/icons/rewards/nodata.png" alt="nodata" />
                        <div className={styles.nodata}>
                            No Items to Display
                        </div>
                    </div>
                )
            }
        </div >
    )
}